import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../../store/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(private router: Router,
    private storageService: StorageService) { }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const retrievedUserData = this.storageService.getUserData();
    const isLoggedIn = retrievedUserData == null ? false : retrievedUserData.isLoggedIn;
    if (isLoggedIn == true) {
      return true;
    }
    this.router.navigate(['/auth/login']);
    return false;
  }

}
