export const Icons = [
  {
    iconName: "email",
    iconPath: "assets/Iconly/Bold/Message.svg"
  },
  {
    iconName: "lock",
    iconPath: "assets/Iconly/Bold/Lock.svg"
  },
  {
    iconName: "close-bold",
    iconPath: "assets/Iconly/Bold/Close Square.svg"
  },
  {
    iconName: "profile",
    iconPath: "assets/Iconly/Bold/Profile.svg"
  },
  {
    iconName: "hide",
    iconPath: "assets/Iconly/Bold/Hide.svg"
  },
  {
    iconName: "calendar",
    iconPath: "assets/Iconly/Bold/Calendar.svg"
  },
  {
    iconName: "document",
    iconPath: "assets/Iconly/Light/Document.svg"
  },
  {
    iconName: "document1",
    iconPath: "assets/Iconly/Bold/Document.svg"
  },
  {
    iconName: "arrow-down",
    iconPath: "assets/Iconly/Bold/Arrow - Down 2.svg"
  },
  {
    iconName: "arrow-bold-left",
    iconPath: "assets/Iconly/Bold/Arrow - Left 2.svg"
  },
  {
    iconName: "arrow-bold-right",
    iconPath: "assets/Iconly/Bold/Arrow - Right 2.svg"
  },
  {
    iconName: "category",
    iconPath: "assets/Iconly/Light/Category.svg"
  },
  {
    iconName: "work-light",
    iconPath: "assets/Iconly/Light/Work.svg"
  },
  {
    iconName: "setting",
    iconPath: "assets/Iconly/Light/Setting.svg"
  },
  {
    iconName: "users",
    iconPath: "assets/Iconly/Light/3 User.svg"
  },
  {
    iconName: "plus",
    iconPath: "assets/Iconly/Curved/Plus.svg"
  },
  {
    iconName: "plus-1",
    iconPath: "assets/Iconly/Bulk/Plus.svg"
  },
  {
    iconName: "plus-bold",
    iconPath: "assets/Iconly/Bold/Plus.svg"
  },
  {
    iconName: "heart",
    iconPath: "assets/Iconly/Curved/Heart.svg"
  },
  {
    iconName: "notification",
    iconPath: "assets/Iconly/Bold/Notification.svg"
  },
  {
    iconName: "arrow-light-left",
    iconPath: "assets/Iconly/Light/Arrow - Left 2.svg"
  },
  {
    iconName: "arrow-back",
    iconPath: "assets/Iconly/Light/Arrow - Left.svg"
  },
  {
    iconName: "arrow-light-right",
    iconPath: "assets/Iconly/Light/Arrow - Right 2.svg"
  },
  {
    iconName: "search-light",
    iconPath: "assets/Iconly/Light/Search.svg"
  },
  {
    iconName: "search",
    iconPath: "assets/Iconly/Bold/Search.svg"
  },
  {
    iconName: "arrow-right",
    iconPath: "assets/Iconly/Bold/Arrow - Right 2.svg"
  },
  {
    iconName: "arrow-up",
    iconPath: "assets/Iconly/Bold/Arrow - Up 2.svg"
  },
  {
    iconName: "arrow-light-up",
    iconPath: "assets/Iconly/Light/Arrow - Up 2.svg"
  },
  {
    iconName: "time",
    iconPath: "assets/Iconly/Bold/Time Circle.svg"
  },
  {
    iconName: "info",
    iconPath: "assets/Iconly/Bold/Info Circle.svg"
  },
  {
    iconName: "info-round",
    iconPath: "assets/Iconly/Light/Danger Circle.svg"
  },
  {
    iconName: "info-outline",
    iconPath: "assets/Iconly/Light-Outline/Danger Circle.svg"
  },
  {
    iconName: "filter",
    iconPath: "assets/Iconly/Bold/Filter.svg"
  },
  {
    iconName: "arrow-right-circle",
    iconPath: "assets/Iconly/Bold/Arrow - Right Circle.svg"
  },
  {
    iconName: "dollar",
    iconPath: "assets/Iconly/Bold/dollar.svg"
  },
  {
    iconName: "star",
    iconPath: "assets/Iconly/Bold/Star.svg"
  },
  {
    iconName: "more",
    iconPath: "assets/Iconly/Light/More Circle.svg"
  },
  {
    iconName: "wallet",
    iconPath: "assets/Iconly/Bulk/Wallet.svg"
  },
  {
    iconName: "paper",
    iconPath: "assets/Iconly/Bulk/Paper.svg"
  },
  {
    iconName: "logout",
    iconPath: "assets/Iconly/Bold/Logout.svg"
  },
  {
    iconName: "login",
    iconPath: "assets/Iconly/Bold/Login.svg"
  },
  {
    iconName: "work",
    iconPath: "assets/Iconly/Bulk/Work.svg"
  },
  {
    iconName: "email-light",
    iconPath: "assets/Iconly/Curved/Message.svg"
  },
  {
    iconName: "chat",
    iconPath: "assets/Iconly/Bold/Chat.svg"
  },
  {
    iconName: "tick",
    iconPath: "assets/Iconly/Bold/Tick Square.svg"
  },
  {
    iconName: "camera",
    iconPath: "assets/Iconly/Light/Camera.svg"
  },
  {
    iconName: "category-bulk",
    iconPath: "assets/Iconly/Bulk/Category.svg"
  },
  {
    iconName: "show",
    iconPath: "assets/Iconly/Bold/Show.svg"
  },
  {
    iconName: "pencil",
    iconPath: "assets/Iconly/Bold/Edit Square.svg"
  },
  {
    iconName: "dropdown",
    iconPath: "assets/Iconly/Broken/Arrow - Down Circle.svg"
  },
  {
    iconName: "close",
    iconPath: "assets/Iconly/Curved/Close Square.svg"
  },
  {
    iconName: "edit",
    iconPath: "assets/Iconly/Bold/Edit.svg"
  },
  {
    iconName: "delete",
    iconPath: "assets/Iconly/Bold/Delete.svg"
  },
  {
    iconName: "close-1",
    iconPath: "assets/Iconly/Bold/Close Square.svg"
  },
  {
    iconName: "close-2",
    iconPath: "assets/Iconly/Bulk/Close Square.svg"
  },
  {
    iconName: "ticket",
    iconPath: "assets/Iconly/Bold/Ticket.svg"
  },
  {
    iconName: "time-1",
    iconPath: "assets/Iconly/Bold/Time Square.svg"
  },
  {
    iconName: "chart",
    iconPath: "assets/Iconly/Bold/Chart.svg"
  },
  {
    iconName: "copy",
    iconPath: "assets/Iconly/extra-icon/copy.svg"
  },
  {
    iconName: "dollarLight",
    iconPath: "assets/Iconly/Bold/dollarLight.svg"
  },
  {
    iconName: "drag",
    iconPath: "assets/Iconly/Bold/drag.svg"
  },
  {
    iconName: "vertical-dots",
    iconPath: "assets/Iconly/Bold/verticalDot.svg"
  },
  {
    iconName: "horizontal-dots",
    iconPath: "assets/Iconly/Bold/horizontalDot.svg"
  },
  {
    iconName: "add",
    iconPath: "assets/Iconly/Bold/add.svg"
  },
  {
    iconName: "minus",
    iconPath: "assets/Iconly/Bold/minus.svg"
  },
  {
    iconName: "drag-bold",
    iconPath: "assets/Iconly/Bold/drag-bold.svg"
  },
  {
    iconName: "profile-light",
    iconPath: "assets/Iconly/Light/Profile.svg"
  },
  {
    iconName:"active-light",
    iconPath: "assets/Iconly/Light/Activity.svg"
  },
  {
    iconName: "report",
    iconPath: "assets/Iconly/Curved/Chart.svg"
  },
]
