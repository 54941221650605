import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './view/main-layout-container/main-layout/main-layout.component';
import { AuthGuard } from './core/guards/auth-guard/auth.guard';
import { PrivacyComponent } from './view/settings-container/privacy/privacy.component';

const routes: Routes = [
  {
    path: "",
    canActivateChild: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "/appointments",
        pathMatch: "full"
      },
      {
        path: "appointments",
        data: { pageTitle: 'Appointments' },
        loadChildren: () => import('src/app/view/appointments-container/appointments-container.module').then(m => m.AppointmentsContainerModule)
      },
      {
        path: "customers",
        data: { pageTitle: 'Customers' },
        loadChildren: () => import('src/app/view/customers-container/customers-container.module').then(m => m.CustomersContainerModule)
      },
      {
        path: "services",
        data: { pageTitle: 'Services' },
        loadChildren: () => import('src/app/view/services-container/services-container.module').then(m => m.ServicesContainerModule)
      },
      {
        path: "settings",
        data: { pageTitle: 'Settings' },
        loadChildren: () => import('src/app/view/settings-container/settings-container.module').then(m => m.SettingsContainerModule)
      },
      {
        path: "reports",
        data: { pageTitle: 'Report Management'},
        loadChildren: () =>
          import(
            "src/app/view/reports-container/reports-container.module"
          ).then((m) => m.ReportsContainerModule),
      }
    ]
  },
  {
    path: "privacy",
    component: PrivacyComponent
  },
  {
    path: "auth",
    loadChildren: () => import("./view/auth-container/auth-container.module").then(m => m.AuthContainerModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
