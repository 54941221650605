import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { UserFacadeService } from 'src/app/facades/user/user-facade.service';
import { LogoutComponent } from '../../auth-container/logout/logout.component';
import { Location } from "@angular/common"
import { SubscriptionFacadeService } from 'src/app/facades/subscription/subscription-facade.service';
import { UserFeedbackState } from 'src/app/core/utilities/user-feedback-state/user-feedback-state';
import { StorageService } from 'src/app/core/store/storage/storage.service';
import { SharedService } from 'src/app/core/services/shared/shared.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent implements OnInit {

  pageTitle!: string;
  selectedIndex: number = 0;
  userId: number;
  profileImage: string = '';
  showLegend: boolean = false;
  changeIndex: number;
  hiddenTitle: boolean = true;
  @ViewChild(MatMenuTrigger) legends: MatMenuTrigger;
  userFeedback = new UserFeedbackState();
  legendTitle: any;
  intervalId: NodeJS.Timer;
  fromClick: boolean = false;
  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userFacadeService: UserFacadeService,
    private bottom: MatBottomSheet,
    private subscriptionFacade: SubscriptionFacadeService,
    private sharedService: SharedService,
    private storageService: StorageService) {
    const retrievedUserData = this.storageService.getUserData();
    this.userId = retrievedUserData.sId;
    this.getStylistDetails();
    this.setPageTitle();
  }

  getFeatureAccess(fromClick, value) {
    this.userFeedback.startLoading();
    try {
      let data = {
        stylistId: this.userId,
      };
      this.subscriptionFacade.getFeatureAccess(data).subscribe({
        next: (res: any) => {
          const retrieveData = this.storageService.getUserData();
          if (retrieveData.isLoggedIn) {
            this.storageService.setUserData({
              ...retrieveData,
              "isCalendarAccess": res.isCalendarAccess,
              "isDoubleBookingAccess": res.isDoubleBookingAccess,
              "isRecurringAccess": res.isRecurringAccess,
              "isReportsAccess": res.isReportsAccess,
              "isEmailNotification": res.isEmailNotification,
              "isSMSNotification": res.isReportsAccess,
              "locationLimitReached": res.locationLimitReached,
              "notesAccess": res.notesAccess,
              "onlineBookAccess": res.onlineBookAccess,
              "subscriptionExpired": res.subscriptionExpired
            });
            if (fromClick) {
              if (value != '') {
                this.breadcrumbClick(value);
              }
            } else {
              this.fromClick = false;
            }
          }
        },
        error: (error) => {
          //this.showError(error);
          this.userFeedback.stopLoading();
        },
      });
    } catch (error) {
      //this.showError(error);
      this.userFeedback.stopLoading();
    }
  }


  getStylistDetails() {
    try {
      const retrievedUserData = this.storageService.getUserData();
      this.userId = retrievedUserData.sId;
      this.userFacadeService.getUser(this.userId).subscribe({
        next: (response: any) => {
          this.storageService.setUserData({
            ...retrievedUserData,
            "lId": response.locationId,
            "recordStatus": response.recordStatus
          })
          if (response.images != '' && response.images != null && response.images != undefined) {
            this.profileImage = response.images
          } else {
            this.profileImage = "/assets/images/avatar.svg"
          }
          this.getFeatureAccess(false, '');
        },
        error: (error) => {
          this.profileImage = "/assets/images/avatar.svg"
        }
      });
    } catch (error: any) {
      this.profileImage = "/assets/images/avatar.svg"
    }
  }

  setPageTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let parentRoute = this.activatedRoute.firstChild;
        let route = this.activatedRoute.firstChild?.firstChild;
        parentRoute.data.subscribe((dat) => {
          const parentPage = dat["pageTitle"];
          this.pageTitle = parentPage;
          route.data.subscribe((d) => {
            if (parentPage == d["pageTitle"]) {
              this.hiddenTitle = true;
            } else {
              this.hiddenTitle = false;
            }
          });
        })
        if (route?.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route: any) => route.data)
    ).subscribe((data: any) => {
      let findIndex: any = this.activatedRoute.routeConfig?.children;
      this.legendTitle = data.pageTitle;
      findIndex.forEach((e: any, i: any) => {
        if (e.data?.pageTitle == this.pageTitle) {
          this.selectedIndex = i - 1;
        } else {
          return;
        }
      });
    })
  }
  ngOnInit(): void {
    this.sharedService.getUpdateObservable().subscribe(() => {
      this.getStylistDetails();
    });
  }

  breadcrumbClick(pageTitle) {
    switch (pageTitle) {
      case 'Appointments':
        this.selectedIndex = 0;
        this.router.navigate(['/appointments']);
        break;
      case 'Customers':
        this.selectedIndex = 1;
        this.router.navigate(['/customers']);
        break;
      case 'Services':
        this.selectedIndex = 2;
        this.router.navigate(['/services']);
        break;
      case 'Settings':
        this.selectedIndex = 3;
        this.router.navigate(['/settings']);
        break;
    }
  }
  onTabClick(event) {
    // Get the label of the clicked tab.
    const clickedTabLabel = event.target.textContent.trim();
    this.fromClick = true;
    this.getFeatureAccess(this.fromClick, clickedTabLabel);
    // if (clickedTabLabel != '') {
    //   this.breadcrumbClick(clickedTabLabel);
    // }
  }
  logout() {
    const config: MatBottomSheetConfig = {
      hasBackdrop: true, // Enable backdrop
    };

    const bottomSheetRef: MatBottomSheetRef = this.bottom.open(LogoutComponent, {
      panelClass: 'custom-style',
      ...config,
    });

    bottomSheetRef.afterOpened().subscribe(() => {
      // Listen for backdrop click event and close the bottom sheet
      const backdrop = document.querySelector('.cdk-overlay-backdrop');
      if (backdrop) {
        backdrop.addEventListener('click', () => {
          bottomSheetRef.dismiss();
        });
      }
    });
  }
  //for navigating to edit profile
  viewProfile() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['settings']);
    });
  }
  //for navigating to sms report
  viewReport() {
     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['reports/sms-report']);
        });
 
  }
  back() {
    this.location.back();
  }
}
