<div class="container">
  <header class="head">
    <div class="top-header">
      <div class="logo-container">
        <div class="logo-menu">
          <!-- <img  src="/assets/images/Mvm2Fav.png" alt="">
          <h3 class="bread-crumb" (click)="breadcrumbClick(pageTitle)">{{pageTitle}}</h3> -->
          <img class="logo-img" src="/assets/images/Mvm2Fav.png" alt="">
          <p class="logo-title">MYVISITMAKER</p>
        </div>
        
        <div class="bread-crumbs" *ngIf="hiddenTitle">
          <!-- <mat-icon svgIcon="arrow-back" class="back-icon" style="cursor: pointer;" (click)="back()"></mat-icon> -->
          <h3 (click)="breadcrumbClick(pageTitle)">{{pageTitle}}</h3>
        </div>
      </div>
      <!-- <button mat-button [matMenuTriggerFor]="afterMenu">After</button> -->
      <mat-menu class="legend-menu" #legends="matMenu" xPosition="after" yPosition="below">
        <mat-list class="legend-list" *ngIf="legendTitle == 'Appointments'">
          <mat-list-item class="legend-color">
            <li><span class="confirm"></span> Confirmed</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li><span class="pending"></span> Approval Pending</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject"></span> Rejected</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="past"></span> Past</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="open"></span> Open Hours</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="break"></span> Closed Hours</li>
          </mat-list-item>
        </mat-list>
        <mat-list class="legend-list" *ngIf="legendTitle == 'View Customer' || legendTitle == 'New Appointment' || legendTitle == 'Edit/View Appointment'">
          <mat-list-item class="legend-color">
            <li><span class="confirm"></span> Confirmed</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li><span class="pending"></span> Approval Pending</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject"></span> Rejected</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="past"></span> Past</li>
          </mat-list-item>
        </mat-list>
        <mat-list class="legend-list" *ngIf="legendTitle == 'Services'">
          <mat-list-item class="legend-color">
            <li> <span class="active"></span>Active</li>
          </mat-list-item>
          <mat-list-item class="legend-color">
            <li> <span class="reject"></span>Inactive</li>
          </mat-list-item>
        </mat-list>
      </mat-menu>
      <div class="menu-desktop">
        <div class="center">
        <mat-tab-group animationDuration="0ms" class="menu-align" [selectedIndex]="selectedIndex" (click)="onTabClick($event)">
          <mat-tab label="Appointments">
            <ng-template mat-tab-label>
              <span class="menu-click" (click)="breadcrumbClick('Appointments')">
                <mat-icon svgIcon="document"></mat-icon>
                <small>Appointments</small>
              </span>
            </ng-template>
          </mat-tab>
          <mat-tab label="Customers">
            <ng-template mat-tab-label>
              <span class="menu-click" (click)="breadcrumbClick('Customers')">
                <mat-icon svgIcon="users"></mat-icon>
                <small>Customers</small>
              </span>
            </ng-template>
          </mat-tab>
          <mat-tab label="Services">
            <ng-template mat-tab-label>
              <span class="menu-click" (click)="breadcrumbClick('Services')">
                <mat-icon svgIcon="work-light"></mat-icon>
                <small>Services</small>
              </span>
            </ng-template>
          </mat-tab>
          <mat-tab label="Settings">
            <ng-template mat-tab-label>
              <span class="menu-click" (click)="breadcrumbClick('Settings')">
                <mat-icon svgIcon="setting"></mat-icon>
                <small>Settings</small>
              </span>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div> 
      </div>
     
      <div class="notify-icons">
        <span class="legend-icon" *ngIf="legendTitle=='Appointments' || legendTitle=='View Customer'|| legendTitle=='Services' || legendTitle == 'New Appointment' || legendTitle == 'Edit/View Appointment'">
          <mat-icon svgIcon="info-round" [matMenuTriggerFor]="legends"
            (click)="showLegend = !showLegend"></mat-icon>
        </span>
        <span class="legend-icon1" *ngIf="pageTitle=='Settings' || legendTitle=='Customers' || legendTitle=='Create / Edit Customer' || legendTitle=='Create Service' || legendTitle=='Edit Service' || legendTitle=='Report Management'">
          <mat-icon class="status-active-icon">circle</mat-icon>
        </span>
        <img *ngIf="!imgPic" class="main-profile" src="/assets/images/avatar.svg" [matMenuTriggerFor]="Profile" width="25px" height="25px"
          alt="">
        <img *ngIf="imgPic" class="main-profile" [src]="profileImage" [matMenuTriggerFor]="Profile" width="25px" height="25px" alt="">
        <mat-menu #Profile="matMenu" class="profile-menu">
          <button mat-menu-item (click)="viewProfile()">Profile</button>
          <button mat-menu-item (click)="viewReport()">Reports</button>
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </div>
    </div>
  </header>

  <div class="body-container">
    <router-outlet></router-outlet>
  </div>

  <footer class="footer-align">
    <div class="center">
      <mat-tab-group animationDuration="0ms" class="menu-align" [selectedIndex]="selectedIndex" (click)="onTabClick($event)">
        <mat-tab label="Appointments">
          <ng-template mat-tab-label>
            <span class="menu-click" (click)="breadcrumbClick('Appointments')">
              <mat-icon svgIcon="document"></mat-icon>
              <small>Appointments</small>
            </span>
          </ng-template>
        </mat-tab>
        <mat-tab label="Customers">
          <ng-template mat-tab-label>
            <span class="menu-click" (click)="breadcrumbClick('Customers')">
              <mat-icon svgIcon="users"></mat-icon>
              <small>Customers</small>
            </span>
          </ng-template>
        </mat-tab>
        <mat-tab label="Services">
          <ng-template mat-tab-label>
            <span class="menu-click" (click)="breadcrumbClick('Services')">
              <mat-icon svgIcon="work-light"></mat-icon>
              <small>Services</small>
            </span>
          </ng-template>
        </mat-tab>
        <mat-tab label="Settings">
          <ng-template mat-tab-label>
            <span class="menu-click" (click)="breadcrumbClick('Settings')">
              <mat-icon svgIcon="setting"></mat-icon>
              <small>Settings</small>
            </span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </footer>
</div>
