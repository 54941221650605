import { ErrorHandler, Injectable, Injector } from '@angular/core';  
import { Router } from '@angular/router';  
import { HttpErrorResponse } from '@angular/common/http';  

@Injectable()  
export class HandleGlobalErrorService implements ErrorHandler {  
  constructor(private injector: Injector) {}  

  handleError(error: any): void {  
    const router = this.injector.get(Router);  
    if (error instanceof HttpErrorResponse) {  
      // Handle HTTP errors  
      if (error.status === 400) {  
        router.navigate(['/auth/login']); // Redirect to login  
      } else {  
        router.navigate(['/']); 
      }  
    } else {  
      // Handle client-side errors  
      router.navigate(['/']); 
    }  
  }  
}  