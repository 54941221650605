import { Component, OnInit } from '@angular/core';
import { AuthFacadeService } from 'src/app/facades/auth/auth-facade.service';
import { Router } from '@angular/router';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authFacadeService: AuthFacadeService, private router: Router, private bottomSheet: MatBottomSheetRef<LogoutComponent>) { }
  /**
   * for dialog popup
   */
  // @Inject(MAT_DIALOG_DATA) data: { message: string },
  // public dialogRef: MatDialogRef<LogoutComponent>
  ngOnInit(): void {
  }

  // logout
  logout() {
    this.authFacadeService.signOut().then((res: any) => {
      localStorage.clear();
      //  switch 
      // const domain=environment.domain;
      // const pastDate = new Date(0).toUTCString();
      // document.cookie = `myObjectCookie=; domain=${domain}; path=/; expires=${pastDate}`;
      this.router.navigate(['auth/login']);
    });
    // this.dialogRef.close();
    this.bottomSheet.dismiss();
  }

  close() {
    this.bottomSheet.dismiss();
  }

}
